

































































import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {}
})
export default class CustomerQuotationList extends Vue {
    searchTerm = '';
    showFilterModal = false;
    data = [];
    currentx = 10;
}
